/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, {memo} from 'react';
import SubHeadingDetails from './SubHeading.config.json';

const SubHeading = (props) => {
    let { label, subHead, configType, subConfig, refferalInfo } = props;
    refferalInfo = refferalInfo ? refferalInfo : [];

let tabLabel = refferalInfo?.find((item) => item.config_type?.toString() === configType?.toString())?.referral_forms.find((list) => list.sub_config_type?.toString() === subConfig?.toString())?.label;
let message: string | any = Object.entries(SubHeadingDetails).map(([key, value]) => {
    
    if(subHead === key) {
        let finalMessage = SubHeadingDetails[key].message1
        return finalMessage?.replace("$$--$$", tabLabel);
    }
});

    return (
        <div className="container-fluid mb-5">
          <h2>{message} </h2> 
        </div>
    );
};

export default SubHeading;
