/** @jsx jsx */
import React, {memo} from 'react';
import { jsx } from '@emotion/core';
import { fieldBox } from '../components/styles';

const Radio = ({ data, sectionIndex, questionIndex, state, handleChange, updateLocalState, disableField }) => {
    const dataState = Array.isArray(state)
        ? state.length > 0 && state[sectionIndex] && state[sectionIndex][data.question]
        : state.client_form[data.question?.replace(/ /g, '_')] || state.client_form[data.question];

    return (
        <React.Fragment>
            {data.suggested_answers.map((ans, i) => (
                <div
                    key={i}
                    css={fieldBox}
                    style={{
                        width: '47.8%',
                        display: ans?.is_deleted
                            ? (state.isEdit === 'true' &&  ans.value === data.answer?.toString())
                                ? 'inline-block'
                                : 'none'
                            : 'inline-block'
                    }}
                >
                    <React.Fragment>
                        <input
                            type="radio"
                            data-jump={
                                data.suggested_jump.length > 0
                                    ? data.suggested_jump.map((sj) =>
                                          sj !== null && ans.value === sj.answer ? (sj.jumpto ? sj.jumpto : '') : ''
                                      )
                                    : ''
                            }
                            data-quesjump={
                                data.suggested_jump.length > 0
                                    ? data.suggested_jump.map((sj) =>
                                          sj !== null && ans.value === sj.answer
                                              ? sj.question_jumpto
                                                  ? sj.question_jumpto
                                                  : ''
                                              : ''
                                      )
                                    : ''
                            }
                            //data-jump = {data.suggested_jump.length > 0 ?data.suggested_jump.jumpto:""}
                            data-id={i}
                            data-idx={sectionIndex}
                            data-idy={questionIndex}
                            id={`${ans.value} - ${data.question}`}
                            //name={data.question.replace(/ /g, '_')}
                            value={ans.id}
                            checked={
                                (dataState && dataState === ans.id.toString()) ||
                                dataState?.toString() === ans?.value?.toString() ||
                                dataState?.value == ans?.value || dataState?.value == ans?.id
                                
                            }
                            disabled={disableField}
                            data-type={data.answer_type.toLowerCase()}
                            onChange={(e) => handleChange(e, data, sectionIndex, questionIndex, state, updateLocalState, i, ans.value)}
                        />{' '} 
                        <label htmlFor={i}>
                            {ans.value}{' '}
                            {ans?.is_deleted && (state.isEdit === 'true' &&  ans.value === data.answer?.toString() )? (
                                <strong style={{ color: '#f45050' }}> ( This option is no longer available. ) </strong>
                            ) : (
                                ''
                            )}
                        </label>
                        
                    </React.Fragment>
                    
                </div>
                
            ))}
        </React.Fragment>
    );
};

export default memo(Radio);
