import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, RouteComponentProps, useLocation, useHistory } from 'react-router-dom';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { AppState } from '../redux-modules/root';
import { ContainerProps } from './Container';
import * as dynamicclient from '../redux-modules/dynamicclient';
import * as Types from '../api/definitions';
import ClientSearch from '../components/ClientSearch';
import DynamicClientDetailsContainer from './DynamicClientDetailsContainer';
import EditClientContainer from './EditClientContainer';
import DynamicNewClientContainer from './DynamicNewClientContainer';
import { KeyUpService } from '../Services/KeyUpService';
import { domainPath } from '../App';
interface MatchParams {
    index: string;
}

interface MatchProps extends RouteComponentProps<MatchParams> {}

export interface DynamicExistingClientContainerState {
    isLoading: boolean;
    error: string;
    hasError: boolean;
    program_completion_response: string | null;
    is_Searched: boolean;
    config: string;
    subConfig: string;
}

export interface DynamicExistingClientContainerProp extends ContainerProps, WithSnackbarProps {
    dynamicSearchClient: (
        data: any,
        is_accessToken: string,
        configType: string,
        subConfigType: string,
        version: number,
        keySearch: any
    ) => void;
    getSearchFields: (  config: string, subConfig: string, is_accessToken: string ) => void;
    // getAvailablePrograms: () => Promise<void>;
    // getReferral: () => Promise<void>;
}

export class DynamicExistingClientContainer extends React.Component<
    DynamicExistingClientContainerProp,
    DynamicExistingClientContainerState
> {
    constructor(props: DynamicExistingClientContainerProp) {
        super(props);
        this.state = this.getInitialState();
    }
    getInitialState() {
        return {
            isLoading: false,
            hasError: false,
            error: '',
            program_completion_response: null,
            is_Searched: false,
            config: "",
            subConfig: ""
        };
    }

    async componentDidMount() {  
        this.props.closeSnackbar();
        const is_accessToken: string | any = this.props.user && this.props.user.user.accessToken;
        const {Config, subConfig} = KeyUpService.getConfSubConf(this.props.location.pathname);
        await this.props.getSearchFields(Config, subConfig, is_accessToken)
        this.setState({config:Config, subConfig:subConfig})
    }

    async componentDidUpdate(prevProps, prevState) {
        const is_accessToken: string | any = this.props.user && this.props.user.user.accessToken;
        if (!this.props.location.pathname?.includes("edit-details") &&this.props.location !== prevProps.location) {
            const {Config, subConfig} = KeyUpService.getConfSubConf(this.props.location.pathname);
            await this.props.getSearchFields(Config, subConfig, is_accessToken)
            this.setState({config:Config, subConfig:subConfig, is_Searched: false})
        }
      }

    dynamicSearchClient = async ( 
        data: any,
        keySearch: any
    ) => {
        const is_accessToken: any = this.props.user && this.props.user.user.accessToken;
        const locationVersionComplete =
            this.props && this.props.location && (this.props.location.state as Types.CustomizedHistoryState);
        const locationVersion: string = locationVersionComplete?.version;

        const version: any =
            locationVersion &&
            (parseInt(locationVersion.substring(1)) > 0
                ? parseInt(locationVersion.substring(1)) - 1
                : parseInt(locationVersion.substring(1)));
        try {
            const {Config, subConfig} = KeyUpService.getConfSubConf(this.props.location.pathname);
            await this.props.dynamicSearchClient(data, is_accessToken, Config, subConfig, version, keySearch);
            this.setState({
                is_Searched: true
            });
        } catch (error) {
            console.log(error);
            const { history } = this.props;
            if (error.status === 403) {
                history.push(`/${domainPath}/logout/`);
            }
        }
    };

    isDataAvailable = () => {
        this.setState({
            is_Searched: false
        });
    };

    render() {
        const { client: clientState, dynamicclient: questionsState  } = this.props;
        const clientList = (clientState && clientState.clientList) || {};
        let uniqueField: any = (clientState && clientState.uniqueIdField) || '';
        const searchFieldsList = (clientState && clientState.searchFieldsList) || {};
        let configuredQuestionsList: any = (questionsState && questionsState.configuredQuestionsList) || [];
        const headerColor: any = this.props.user && this.props.user.user.header_color;
        const { is_Searched } = this.state;
        
        return (
            <Switch>
                {/* <Route exact path={`/${domainPath}/existing-client/search?config=:Config&subConfig=:subConfig`}> */}
                <Route exact path={`/${domainPath}/existing-client/config=:config&subConfig=:subConfig`}>
                    <ClientSearch
                        clientList={is_Searched ? Object.values(clientList) : []}
                        {...this.state}
                        headerColor={headerColor}
                        user={this.props && this.props.user}
                        onSearchSubmit={this.dynamicSearchClient}
                        clearData={this.isDataAvailable}
                        searchFieldsList={searchFieldsList}
                        uniqueField={uniqueField}
                        configuredQuestionsList={configuredQuestionsList}
                    />
                </Route>
                <Route
                    exact
                    path={`/${domainPath}/existing-client/client-details/config=:config&subConfig=:subConfig&index=:index`}
                    component={DynamicClientDetailsContainer}
                ></Route>
                <Route
                    exact
                    path={`/${domainPath}/existing-client/edit-details/:index/2`}
                    component={EditClientContainer}
                ></Route>
                <Route
                    exact
                    path={`/${domainPath}/existing-client/edit-details/:index/program-selection`}
                    component={EditClientContainer}
                ></Route>
                <Route
                    exact
                    path={`/${domainPath}/existing-client/edit-details/config=:config&subConfig=:subConfig&index=:index&isEdit=:isEdit&Rerefer=:Rerefer`} 
                    component={DynamicNewClientContainer}
                ></Route>
            </Switch>
        );
 }
}

const mapStateToProps = (state: AppState) => {
    return {
        client: state.client,
        program: state.program,
        referral: state.referral,
        dynamicclient: state.dynamicclient,
        user: state.user
    };
};

const mapDispatchToProps = {
    dynamicSearchClient: dynamicclient.actions.dynamicSearchClient,
    getSearchFields: dynamicclient.actions.getSearchFields
    
}; 

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(DynamicExistingClientContainer));