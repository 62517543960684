/** @jsx jsx */
import React, { Fragment, useState } from 'react';
import { withRouter, RouteComponentProps, Route, useHistory, useLocation } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { jsx, css } from '@emotion/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { menuData } from './MenuItems';
import { KeyUpService } from '../../Services/KeyUpService';
import upArrow from './../../assets/up-arrow.svg';
import downArrow from './../../assets/down-arrow.svg';

interface MenuNavProps extends RouteComponentProps {
    is_configured: boolean;
    is_pwd_expired: boolean;
    domainPath: string;
    headerColor: string;
    role_type: string;
    org_type: number;
    is_dashboard_published: string;
    referral_info: any;
    billing_status: boolean;
}

const nav = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 30px;
    @media all and (min-width: 520px) {
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
    }
`;

const menuButton = css`
    width: 100px;
    height: 50px;
    @media all and (max-width: 520px) {
        width: 70px;
        height: 35px;
        font-size: 6px !important;
    }
    @media all and (max-width: 768px) {
        font-size: 6px;
    }
    cursor: pointer;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    border-radius: 0px !important;
    font-size: 0.75rem;
    color: #9c9c9c;
    &:hover .subMenuContainer {
      display: block;
    }
    @media all and (min-width: 520px) {
        &:not(:last-child) {
            border-right: 2px solid #c4c4c4 !important;
        }
    }
`;

const subMenuContainer = css`
        margin-bottom: 12px, 
        display: "none"
`;

const subMenuButton = css`
    width: 100px;
    height: 30px;
    @media all and (max-width: 520px) {
        width: 70px;
        height: 35px;
        font-size: 6px !important;
    }
    @media all and (max-width: 768px) {
        font-size: 6px;
    }
    cursor: pointer;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    border-radius: 0px !important;
    border: 1px solid #000 !important;
    font-size: 0.6rem;
    color: #9c9c9c;
    margin: 2px;
    @media all and (min-width: 520px) {
        &:not(:last-child) {
            border-right: 2px solid #c4c4c4 !important;
        }
    }
`;

const Menu = (props: MenuNavProps) => {
    const [openSubMenuIndex, setOpenSubMenuIndex] = useState<number | null>(null);
    const filteredOrg = menuData.filter((item) => item.id === props.org_type);
    const { Config, subConfig } = KeyUpService.getConfSubConf(window.location.pathname);
    const history = useHistory();
    const location = useLocation();

    const values = {
        is_configured: props.is_configured,
        is_pwd_expired: props.is_pwd_expired,
        role_type: props.role_type,
        is_dashboard_published: props.is_dashboard_published,
        domainPath: props.domainPath,
        referral_info: props.referral_info,
        billing_status: props.billing_status
    };

    const toggleSubMenu = (index: number | null) => {
        setOpenSubMenuIndex(openSubMenuIndex === index ? null : index); // Toggle submenu
    };

    const handleSubmenuClick = (item, subItem) => {
        history.push(`/${props.domainPath}/${item.link}/config=${item.configType}&subConfig=${subItem.sub_config_type}`);
    };

    const menuItems = filteredOrg[0]?.menu_list.filter((item) => (item.checkCondition ? item.condition(values) : item));
    

 return (
        <div css={nav}>
            {menuItems?.map((item: any, index) => {
                let link = item.link;
                let name = item.name;
                if (item.hasOwnProperty('referralType')) {
                    props.referral_info.map((ref_menu) => {
                        if (ref_menu.config_type === item.configType) {
                            item.subMenuItems = ref_menu.referral_forms;
                        }

                    })
                    // name = item.referralType ? props.referral_info[item.referralType] ? `${item.name?.split(" ")?.shift()} ${props.referral_info[item.referralType]}` : name : name;
                    link = item.referralType ? props.referral_info[item.referralType] ? `${item.link?.split(" ")?.shift()}${props.referral_info[item.referralType]?.toLowerCase()}` : link : link;
                }


                if (item.referralType) {
                    const isActive = location.pathname.includes(`${item.link}`);
                    return (
                        <div style={{ paddingRight: "1px" }} key={index}>
                            <Route>
                                <Link
                                    onClick={() => toggleSubMenu(index)}
                                    // onMouseOver={() => toggleSubMenu(index)}
                                    //onMouseDown={() => toggleSubMenu(null)}
                                    css={menuButton}
                                    style={
                                        isActive
                                            ? { backgroundColor: '#f5f5f5', color: '#9d9d9d' }
                                            : { backgroundColor: props.headerColor, color: 'white' }
                                    }
                                >
                                    {/* <div> */}
                                    {item.name}
                                    {openSubMenuIndex === index ?
                                        <img src={upArrow} /> :
                                        <img src={downArrow} />
                                    }
                                    {/* </div> */}

                                </Link>
                            </Route>

                            {openSubMenuIndex === index && (
                                <div css={subMenuContainer}>
                                    {item.subMenuItems.map((subItem: any, subIndex) => {
                                        const isActive = location.pathname.includes(item.link) && Config == item.configType && subConfig == subItem.sub_config_type;

                                        return (

                                            <Route
                                                key={subIndex}
                                                path={`/${props.domainPath}/${item.link}`}
                                                children={({ match }) => (
                                                    <Link
                                                        onClick={() => handleSubmenuClick(item, subItem)}
                                                        css={subMenuButton}
                                                        style={
                                                            !isActive
                                                                ? { backgroundColor: '#f5f5f5', color: '#9d9d9d' }
                                                                : { backgroundColor: props.headerColor, color: 'white' }
                                                        }
                                                    >
                                                        {subItem.label}
                                                    </Link>
                                                )}
                                            />
                                        )
                                    }
                                    )}
                                </div>
                            )}
                        </div>
                    )
                } else {
                    return (
                        <Fragment>
                            <Route
                                key={index}
                                path={`/${props.domainPath}/${item.link}`}
                                // exact={activeOnlyWhenExact}
                                children={({ match }) => (
                                    <Link
                                        onClick={() => {
                                            history.push(`/${props.domainPath}/${item.link}`);
                                        }}
                                        onMouseOver={() => toggleSubMenu(null)}
                                        onMouseDown={() => toggleSubMenu(null)}
                                        css={menuButton}
                                        style={
                                            match
                                                ? { backgroundColor: '#f5f5f5', color: '#9d9d9d' }
                                                : { backgroundColor: props.headerColor, color: 'white' }
                                        }
                                    >
                                        {props.is_configured === true ? `${name}` : ''}
                                    </Link>
                                )}
                            />
                        </Fragment>
                    )
                }

            }
            )}
        </div>
    );
};

export default Menu;