/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { components } from 'react-select';
import { default as ReactSelect } from 'react-select';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { label, dropdownField, label1, fieldRow1, label2, twoCol } from './styles';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Add, CloseRounded } from '@material-ui/icons';
import { InitialState, initialState, initialCondition, initialSelection } from './ClientSearchFilters.interface';
import * as Utility from '../Common/Base';
import SubHeading from './SubHeading';
import moment from "moment";

const Option = (props) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <components.Option {...props}>
                <input type="checkbox" checked={props.isSelected} onChange={() => null} /> <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

const colourStyles = {
    option: (styles, { isSelected }) => {
        return {
            ...styles,
            backgroundColor: isSelected && 'transparent',
            color: 'black'
        };
    },
    menuPortal: (base) => ({
        ...base,
        zIndex: 9999 // Ensure the menu appears on top of other elements
    }),
    valueContainer: (base) => ({
        ...base,
        maxHeight: 38, // Set the fixed height for the value container
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    }),
    singleValue: (base) => ({
        ...base,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    })
};

const timeFilterOptions = [{ value: '', label: 'Select Date/Days' }, { value: 1, label: 'Yes' }, { value: 2, label: 'No' }];

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        // marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        height: '50%'
    }
};

const ClientSearchFilters = (props) => {
    const [state, setState] = useState<InitialState>(initialState);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const setInitialState = () => {
        initialState.sections = state.sections;
        initialState.datequestions = state.datequestions;
        initialState.filterBy = [];

        setState({ ...initialState });
    };

    useEffect(() => {

        setState((prevState) => ({
            ...prevState,
            sections: props.configuredQuestionsList
        }));
        const datequestions = props.configuredQuestionsList?.map((section: any) =>
            section.questions.filter((item) => item.answer_type === 'DATE')
        );
        const filteredDateQuestions: any = datequestions?.flat(1);

        setState((prevState) => ({
            ...prevState,
            datequestions: filteredDateQuestions
        }));
    }, []);

    const addRow = (fieldname) => {
        if (fieldname === 'conditions') {
            setState((prevState) => ({
                ...prevState,
                conditions: [
                    ...state.conditions,
                    {
                        section: '',
                        question: '',
                        answer_type: '',
                        questionsList: [],
                        answersList: [],
                        selectedAnswers: []
                    }
                ]
            }));
        } else {
            if (state.selections.length === 0) {
                setState((prevState) => ({
                    ...prevState,
                    selections: [{ section: '', question: [], questionsList: [] }]
                }));

            } else {
                setState((prevState) => ({
                    ...prevState,
                    selections: [...state.selections, { section: '', question: [], questionsList: [] }]
                }));
            }
        }
    };

    const removeRow = (index, fieldname) => {
        if (fieldname === 'conditions') {
            const rows = [...state.conditions];
            rows.splice(index, 1);
            setState((prevState) => ({
                ...prevState,
                conditions: rows
            }));
        } else {
            const rows = [...state.selections];
            rows.splice(index, 1);
            setState((prevState) => ({
                ...prevState,
                selections: rows
            }));
        }
    };

    const handleSelectChange = (index, fieldname) => (evnt) => {
        if (fieldname === 'conditions') {
            const { name, value } = evnt.target;
            const list: any = [...state.conditions];
            list[index][name] = value;
            setState((prevState) => ({ ...prevState, conditions: list }));
            if (name === 'section') {
                if (value.length > 0) {
                    const selectedSectionDetails: any = state.sections.filter((item: any) => item.section === value);
                    const selectedSectionRelatedQuestions =
                        selectedSectionDetails.length > 0 && selectedSectionDetails[0].questions;
                    //Remove questions of answerType date
                    const filteredQuestions = selectedSectionRelatedQuestions.filter(
                        (ques) => ques.answer_type !== 'DATE'
                    );
                    list[index]['questionsList'] = filteredQuestions;
                    setState((prevState) => ({ ...prevState, conditions: list }));
                } else {
                    list[index] = {
                        section: '',
                        question: [],
                        answer: '',
                        answer_type: '',
                        questionsList: [],
                        answersList: []
                    };
                    setState((prevState) => ({ ...prevState, conditions: list }));
                }
            } else if (name === 'question') {
                if (value.length > 0) {
                    const selectedQuestionDetails: any = list[index].questionsList.filter(
                        (item: any) => item.question === value
                    );
                    const selectedQuestionAnswers: any =
                        selectedQuestionDetails.length > 0 && selectedQuestionDetails[0].suggested_answers;
                    const selectedQuestionAnswerType =
                        selectedQuestionDetails.length > 0 && selectedQuestionDetails[0].answer_type;
                    list[index]['answer_type'] = selectedQuestionAnswerType;
                    list[index]['answersList'] = [{ value: 'Select All', id: '*' }, ...selectedQuestionAnswers];
                    setState((prevState) => ({ ...prevState, conditions: list }));
                } else {
                    list[index]['answer_type'] = '';
                    list[index]['answer'] = '';
                    list[index]['answersList'] = [];
                    setState((prevState) => ({ ...prevState, conditions: list }));
                }
            }
        } else {
            
            const { name, value } = evnt.target;
            const list: any = [...state.selections];
            list[index][name] = value;
            setState((prevState) => ({ ...prevState, selections: list }));
            if (name === 'section') {
                if (value.length > 0) {
                    const selectedSectionDetails: any = state.sections.filter((item: any) => item.section === value);
                    let selectedSectionRelatedQuestions =
                        selectedSectionDetails.length > 0 && selectedSectionDetails[0].questions;
                    list[index]['questionsList'] = [{ question: 'Select All', id: '*' }, ...selectedSectionRelatedQuestions];
                    setState((prevState) => ({ ...prevState, selections: list }));
                } else {
                    list[index] = {
                        section: '',
                        question: [],
                        answer: '',
                        answer_type: '',
                        questionsList: [],
                        answersList: []
                    };
                    setState((prevState) => ({ ...prevState, selections: list }));
                }
            }
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setState((prevState) => ({ ...prevState, [name]: value }));
    };

    const onRadioChange = (selectedOption) => {
        const value = selectedOption.value?.toString();
        let updateDateObj = {};
        if (value === 1) {
            updateDateObj = {
                days_count: null,
                comparison: ''
            };
        } else if (value === 2) {
            updateDateObj = {
                start_date: '',
                end_date: ''
            };
        } else {
            updateDateObj = {
                days_count: null,
                comparison: '',
                start_date: '',
                end_date: ''
            };
        }
        setState((prevState) => ({
            ...prevState,
            ...updateDateObj,
            time_filter: value
        }));
    };

    const handleFormSubmit = async (e) => {

        e.preventDefault();
        const conditions = state.conditions?.map((item) => {
            let answervalues = [];
            const filteredArray: any = item.answersList.filter((answer: any) =>
                item.selectedAnswers.some((filterItem: any) => filterItem.value === answer.id)
            );
            const selectedIds: any = filteredArray.map((ob) => (!isNaN(parseInt(ob.id)) ? parseInt(ob.id) : ob.id));
            const selectedValues = filteredArray.map((obj) => obj.value);
            answervalues = item.answer_type === 'NUMBER' || item.answer_type === 'TEXT' ? selectedValues : selectedIds;
            return {
                question: item.question,
                values: answervalues
            };
        });

        const filteredSelections = state.selections?.map((item) => item?.question.map((list: any) => list.label))?.flat() || [];
        const data = {
            conditions: conditions,
            selections: [...filteredSelections],
            time_filter: parseInt(state.time_filter),
            date_field: state.date_field,
            days_count: state.days_count ? state.days_count : null,
            comparison: state.comparison,
            start_date: state.start_date,
            end_date: state.end_date
        };
        try {
            const response = await props.downloadDump(data);
            if (response.status === 'failed') {
                let message = response.message ? response.message : 'Something went wrong. Please try again';
                setErrorMessage(message);
            } else {
                props.setOpenFilters(!props.openFilters);
                setInitialState();
            }
        } catch (e) {
            console.error('Something went wrong')
        }
    };

    const handleMultiSelectChange = (selectedOption, index, name, options) => {
        const isSelectAllSelected = selectedOption.some(
            (option) => option.value === '*'
        );
        let selection = [...state.selections];
        let condition = [...state.conditions];
        const isAllSelected = name === 'selectedAnswers' ? condition[index].selectedAnswers.length === options.length : selection[index].question.length === options.length;
        let finalOptions = options.map((item) => ({
            value: item.id,
            label: name === 'selectedAnswers' ? item.value : item.question,
        }));

        if (isSelectAllSelected && isAllSelected) {
            selectedOption = selectedOption.filter((option) => option.value !== '*');
        } else
            if (isAllSelected) {
                selectedOption = [];
            } else if (isSelectAllSelected) {
                selectedOption = finalOptions;
            }


        if (name === 'selectedAnswers') {
            const list = [...state.conditions];
            list[index][name] = selectedOption;
            setState((prevState) => ({ ...prevState, conditions: list }));
        } else {
            const list = [...state.selections];
            list[index][name] = selectedOption;
            setState((prevState) => ({ ...prevState, selections: list }));
        }
    };

    const filterSelectedQuestions = (index) => {
        let result = state.conditions.find((item, ind) => index !== ind)?.question;

        return result;
    };

    const filterSelectedSections = (index, section) => {
        let result: string[] = [];
        result = state.selections.filter((item, ind) => item.section !== section).map((list) => list.section);

        let finalResult = state.sections.filter(
            (item) => !result.includes(item.section) || result[index] === item.section
        );
        return finalResult;
    };

    const filterByHandler = (e) => {
        const { name, value, checked } = e.target;
        let { filterBy } = state;
        let result = {};
        switch (name) {
            case 'condition':
                if (checked) {
                    result = {
                        conditions: [{
                            section: '',
                            question: '',
                            answer_type: '',
                            questionsList: [],
                            answersList: [],
                            selectedAnswers: []
                        }]
                    };
                } else {
                    result = { conditions: [] };
                }
                break;
            case 'columns':
                if (checked) {
                    result = { selections: [{ section: '', question: [], questionsList: [] }] };
                } else {
                    result = { selections: [] };
                }
                break;
            case 'timefilter':
                if (checked) {
                    result = {
                        time_filter: state.time_filter,
                        date_field: state.date_field,
                        days_count: state.days_count,
                        comparison: state.comparison,
                        start_date: state.start_date,
                        end_date: state.end_date
                    };
                } else {
                    result = {
                        time_filter: '',
                        date_field: '',
                        days_count: null,
                        comparison: '',
                        start_date: '',
                        end_date: ''
                    };
                }
                break;
        }
        if (checked) {
            filterBy.push(value);
        } else {
            filterBy = filterBy.filter((item) => item !== value);
        }

        setState((prevState) => ({
            ...prevState,
            ...result,
            filterBy: filterBy
        }));
    };

    const handleDatepicker = (date, name) => {
        setState((prevState) => ({
            ...prevState,
            [name]: Utility.validateDateFormate(date)
        }));
    };

    return (
        <div className="container-fluid mb-5" style={{ marginTop: '3rem' }}>
            <Modal
                isOpen={props.openFilters}
                onRequestClose={() => props.setOpenFilters(!props.openFilters)}
                style={customStyles}
                ariaHideApp={false}
                contentLabel="Data dump filters"
                key={1}
            >
                <SubHeading label="Client"
                    subHead={"DownloadReport"}
                    configType={props.config}
                    subConfig={props.subConfig}
                    refferalInfo={props.refferalInfo} />
                <div className="col-12">
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <label css={label}>Filter By:</label>

                        </Grid>
                        <Grid item xs={10}>
                            <label>Select the advanced filtering option(s) below. To download the FirstMatch Data Report without advanced filters, click Download.</label>

                        </Grid>
                    </Grid>
                    {errorMessage && (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <label css={label2}>Error: {errorMessage}</label>

                            </Grid>
                        </Grid>
                    )}
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <input
                                value={'1'}
                                onChange={(e) => filterByHandler(e)}
                                name="condition"
                                type="checkbox"
                                className="form-control"
                                checked={state.filterBy.includes('1')}
                            />
                            <label css={label1}>Conditions: </label>
                        </Grid>
                        <Grid item xs={10}>
                            <label>Ability to set filter criteria based on sections, field titles, and answer selections.</label>
                        </Grid>
                    </Grid>

                    {state.filterBy.includes('1') &&
                        state.conditions.map((item: any, index) => (
                            <div key={index} style={{ paddingLeft: '3em' }}>
                                <div css={fieldRow1} key={index}>
                                    <div css={twoCol}>
                                        <label css={label}>Condition {index + 1}:</label>
                                    </div>
                                    <div css={twoCol}>
                                        <select
                                            name="section"
                                            css={dropdownField}
                                            value={item.section}
                                            onChange={handleSelectChange(index, 'conditions')}
                                            required
                                        >
                                            <option value="">Select Section</option>
                                            {state.sections.map((item: any, index) => {
                                                return (
                                                    <option key={index} value={item.section}>
                                                        {item.section}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    <div css={twoCol}>
                                        <select
                                            name="question"
                                            css={dropdownField}
                                            value={item.question}
                                            onChange={handleSelectChange(index, 'conditions')}
                                            required
                                        >
                                            <option value="">Select Question</option>
                                            {item.questionsList.map(
                                                (element, i) =>
                                                    !filterSelectedQuestions(index)?.includes(element.question) &&
                                                    !['TEXT', 'NUMBER'].includes(element.answer_type) && (
                                                        <option key={i} value={element.question}>
                                                            {element.question}
                                                        </option>
                                                    )
                                            )}
                                        </select>
                                    </div>
                                    <div css={twoCol}>
                                        <span data-toggle="popover" data-trigger="focus" data-content="answer">
                                            <ReactSelect
                                                options={item.answersList.map((option) => {
                                                    if (option.value === '') {
                                                        return { value: option.id, label: 'Not Answered' };
                                                    } else {
                                                        return { value: option.id, label: option.value };
                                                    }
                                                })}
                                                isMulti
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                components={{
                                                    IndicatorSeparator: () => null,
                                                    Option
                                                }}
                                                onChange={(selectedOption) =>
                                                    handleMultiSelectChange(
                                                        selectedOption,
                                                        index,
                                                        'selectedAnswers',
                                                        item.answersList
                                                    )
                                                }
                                                // allowSelectAll={true}
                                                menuPortalTarget={document.body}
                                                value={item.selectedAnswers}
                                                styles={colourStyles}
                                                placeholder="Select Answer"
                                            />
                                        </span>
                                    </div>
                                    <Grid item xs={1} key={index}>
                                        <CloseRounded
                                            onClick={() => removeRow(index, 'conditions')}
                                            style={{
                                                fontSize: '24px',
                                                fontWeight: 'bold',
                                                cursor: 'pointer',
                                                color: 'red'
                                            }}
                                        />

                                        {index === state.conditions.length - 1 && (
                                            <Add
                                                onClick={() => addRow('conditions')}
                                                style={{ fontSize: '24px', fontWeight: 'bold', cursor: 'pointer' }}
                                            />
                                        )}
                                    </Grid>
                                </div>
                            </div>
                        ))}
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <input
                                value={'2'}
                                onChange={(e) => filterByHandler(e)}
                                name="columns"
                                type="checkbox"
                                className="form-control"
                                checked={state.filterBy.includes('2')}
                            />
                            <label css={label1}>Columns: </label>
                        </Grid>
                        <Grid item xs={10}>
                            <label>Ability to set filter criteria based on field titles.</label>
                        </Grid>
                    </Grid>
                    {state.filterBy.includes('2') &&
                        state.selections.map((item: any, index) => (
                            <div css={fieldRow1} key={index} style={{ paddingLeft: '3em' }}>
                                <div css={twoCol}>
                                    <label css={label1}>Column Selection {index + 1}:</label>
                                </div>
                                <div css={twoCol}>
                                    <select
                                        name="section"
                                        value={item.section}
                                        css={dropdownField}
                                        onChange={handleSelectChange(index, 'selections')}
                                        required
                                    >
                                        <option value="">Select Section</option>
                                        {filterSelectedSections(index, item.section).map((item: any) => (
                                            <option key={index} value={item.section}>
                                                {item.section}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div css={twoCol}>
                                    <ReactSelect
                                        options={item.questionsList.map((option, index) => {
                                            if (option) {
                                                if (option.question === '') {
                                                    return { value: option.id, label: 'Not Answered' };
                                                } else {
                                                    return { value: option.id, label: option.question };
                                                }
                                            }
                                        })}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        components={{
                                            IndicatorSeparator: () => null,
                                            Option
                                        }}
                                        onChange={(selectedOption) =>
                                            handleMultiSelectChange(selectedOption, index, 'question', item.questionsList)
                                        }
                                        // allowSelectAll={true}
                                        menuPortalTarget={document.body}
                                        value={item.question}
                                        styles={colourStyles}
                                        placeholder="Select Field Title"
                                    />
                                </div>
                                <Grid item xs={1} key={index}>
                                    <CloseRounded
                                        onClick={() => removeRow(index, 'selections')}
                                        style={{
                                            fontSize: '24px',
                                            fontWeight: 'bold',
                                            cursor: 'pointer',
                                            color: 'red'
                                        }}
                                    />
                                    {index === state.selections.length - 1 && (
                                        <Add
                                            onClick={() => addRow('selections')}
                                            style={{ fontSize: '24px', fontWeight: 'bold', cursor: 'pointer' }}
                                        />
                                    )}
                                </Grid>
                            </div>
                        ))}
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <input
                                value={'3'}
                                onChange={(e) => filterByHandler(e)}
                                name="timefilter"
                                type="checkbox"
                                className="form-control"
                                checked={state.filterBy.includes('3')}
                            />
                            <label css={label1}>Time Filter: </label>
                        </Grid>
                        <Grid item xs={10}>
                            <label>Ability to set filter criteria based on Date/Day ranges.</label>
                        </Grid>
                    </Grid>

                    {state.filterBy.includes('3') && (
                        <React.Fragment>
                            <div css={fieldRow1} style={{ paddingLeft: '3em' }}>
                                <div css={twoCol}>
                                    <label css={label}>Time Filter:</label>
                                </div>
                                <div css={twoCol}>
                                    <ReactSelect
                                        options={timeFilterOptions}
                                        closeMenuOnSelect={true}
                                        hideSelectedOptions={false}
                                        onChange={onRadioChange}
                                        styles={colourStyles}
                                        placeholder="Select Date/Days"
                                    />
                                </div>
                            </div>

                            <div css={fieldRow1} style={{ paddingLeft: '3em' }}>
                                {state.time_filter !== '' &&
                                    <React.Fragment>
                                        <div css={twoCol}>
                                            <label css={label1}>Pick Date Field:</label>
                                        </div>
                                        <div css={twoCol}>
                                            <select
                                                onChange={handleChange}
                                                value={state.date_field}
                                                name="date_field"
                                                css={dropdownField}
                                            >
                                                <option value="">Select Date Question</option>
                                                {state.datequestions?.map((item: any, index) => {
                                                    return (
                                                        <option key={index} value={item.question}>
                                                            {item.question}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </React.Fragment>
                                }
                                {state.time_filter === '1' && state.date_field !== '' && (
                                    <React.Fragment>
                                        <div css={twoCol}>
                                            <KeyboardDatePicker
                                                clearable
                                                name="From Date"
                                                format="MM/dd/yyyy"
                                                placeholder="mm/dd/yyyy"
                                                autoOk={true}
                                                allowKeyboardControl={true}
                                                animateYearScrolling={true}
                                                inputVariant="filled"
                                                orientation="landscape"
                                                value={
                                                    state.start_date
                                                        ? moment(state.start_date).locale("en_us").format('YYYY/MM/DD')
                                                        : null
                                                }
                                                onChange={(date) => handleDatepicker(date, 'start_date')}
                                            />
                                        </div>
                                        <div css={twoCol}>
                                            <KeyboardDatePicker
                                                clearable
                                                name="To Date"
                                                format="MM/dd/yyyy"
                                                placeholder="mm/dd/yyyy"
                                                autoOk={true}
                                                allowKeyboardControl={true}
                                                animateYearScrolling={true}
                                                inputVariant="filled"
                                                orientation="landscape"
                                                value={
                                                    state.end_date
                                                        ? moment(state.end_date).locale("en_us").format('YYYY/MM/DD')
                                                        : null
                                                }
                                                onChange={(date) => handleDatepicker(date, 'end_date')}
                                            />
                                        </div>
                                    </React.Fragment>
                                )}
                                {state.time_filter === '2' && (
                                    <React.Fragment>
                                        <div css={twoCol}>
                                            <select
                                                value={state.comparison}
                                                placeholder="Select Comparator"
                                                name="comparison"
                                                onChange={handleChange}
                                                css={dropdownField}
                                            >
                                                <option value="">Select Comparator</option>
                                                <option value="==">==</option>
                                                <option value="<=">&lt;=</option>
                                                <option value=">=">&gt;=</option>
                                                <option value="<">&lt;</option>
                                                <option value=">">&gt;</option>
                                            </select>
                                        </div>
                                        <div css={twoCol}>
                                            <input
                                                value={state.days_count || ''}
                                                placeholder="Days Count"
                                                required
                                                onChange={handleChange}
                                                name="days_count"
                                                type="text"
                                                css={dropdownField}
                                            />
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        </React.Fragment>
                    )}

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            marginTop: '5px',
                        }}
                    >
                        <div className="col-sm-2">
                            <Button
                                size="large"
                                variant="contained"
                                color="secondary"
                                style={{ marginRight: 10, backgroundColor: props.headerColor, color: '#fff' }}
                                onClick={() => setInitialState()}
                            >
                                Clear
                            </Button>
                        </div>
                        <div className="col-sm-2">
                            <Button
                                size="large"
                                variant="contained"
                                color="primary"
                                style={{ marginRight: 10, backgroundColor: props.headerColor, color: '#fff' }}
                                onClick={(e) => handleFormSubmit(e)}
                            >
                                Download
                            </Button>
                        </div>
                        <div className="col-sm-2">
                            <Button
                                size="large"
                                variant="contained"
                                color="primary"
                                style={{ marginRight: 10, backgroundColor: props.headerColor, color: '#fff' }}
                                onClick={() => (props.setOpenFilters(!props.openFilters,),
                                    setInitialState())}
                            >
                                Close
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default ClientSearchFilters;
