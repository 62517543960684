export class NumberFieldService {
    static handleNumberField = (e, data, idx, idy, prevState, updateLocalState) => {
        const { name, value } = e.target;
        const { DynamicQuestions, isEdit, ClientCode, error } = prevState;

        const updatedDynamicQuestions = [...DynamicQuestions];
        const error_msg = data.error_msg || '';

        const val1 = parseInt(data.validation1) || 0;
        const val2 = parseInt(data.validation2) || Number.MAX_SAFE_INTEGER;

        const err = parseInt(value) < val1;
        const err1 = parseInt(value) > val2;

        updatedDynamicQuestions[idx].questions[idy].answer = value;

        let updatedClientForm = { ...prevState.client_form };

        if ( isEdit === 'true' && name === 'Client_Code' ) {
            updatedClientForm = {
                ...updatedClientForm,
                [name]: value,
                Client_Code1: ClientCode
            };
        } else {
            updatedClientForm = {
                ...updatedClientForm,
                [name]: value
            };
        }

        const updatedError = ((err || err1) && error_msg) ?  {
            ...error,
            [name]:  error_msg 
        } : [];

        updateLocalState( {
            DynamicQuestions: updatedDynamicQuestions,
            client_form: updatedClientForm,
            error: updatedError,
            hasError: data.related === 'no' ? false : err || err1
        });
    };
}

export default NumberFieldService;
